import { makeStyles } from "@material-ui/core";
import { primaryColor, borderColor, primaryColor10 } from "../scss/colors.scss";

export const textColor = "#040D13";
export const textColorGrey = "#8A949D";
export const switchTrackColorGrey = borderColor;
export const switchThumbColorPrimary = primaryColor;
export const dangerColor = "#FD3939";
export const dangerColor10 = "#FD39391a";
export const otpInactiveBorderColor = "#919DAB";
export const otpActiveBorderColor = primaryColor;

export const useCommonStyles = makeStyles((theme) => ({
  fontSize12: { color: textColor, fontSize: "12px", lineHeight: "14.5px" },
  fontSize14: { color: textColor, fontSize: "14px", lineHeight: "17px" },
  fontSize16: { color: textColor, fontSize: "16px", lineHeight: "19.5px" },
  fontSize18: { color: textColor, fontSize: "18px", lineHeight: "22px" },
  fontSize20: { color: textColor, fontSize: "20px", lineHeight: "24px" },
  fontSize24: { color: textColor, fontSize: "24px", lineHeight: "30px" },
  fontWeightLight: { fontWeight: "300" },
  fontWeightNormal: { fontWeight: "400" },
  fontWeightBold: { fontWeight: "700" },
  flexRow: { display: "flex", flexDirection: "row" },
  flexColumn: { display: "flex", flexDirection: "column" },
  spaceBetween: { justifyContent: "space-between" },
  textGrey: { color: textColorGrey },
  textWhite: { color: "#FFF" },
  textPrimary: { color: primaryColor },
  marginBottom24: { marginBottom: "24px" },
  noMargin: { margin: "0px" },
  noPadding: { padding: "0px" },
  disabledMinWidth: { minWidth: "unset" },
  positionRelative: { position: "relative" },
  textCenter: { textAlign: "center" },
  textRight: { textAlign: "right" },
  textUnderlined: { textDecoration: "underline" },
  cursorPointer: { cursor: "pointer" },
  mobileDisplay: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  },
  desktopDisplay: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  textNoTransform: {
    textTransform: "initial",
  },
  mobileNoPadding: {
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  textOverflowEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  widthFitContent: {
    width: "fit-content",
  },
  textUppercase: {
    textTransform: "uppercase",
  },
}));

export const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    borderBottom: `2px solid ${borderColor}`,
  },
  indicator: {
    backgroundColor: primaryColor,
    height: "3px",
  },
  tabRoot: {
    padding: "10px 25px",
    textTransform: "none",
    minWidth: "unset",
    "& > span": {
      fontSize: "18px",
      lineHeight: "22px",
      width: "auto",
      fontWeight: "400",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      marginRight: "24px",
    },
  },
  tabPanelRoot: {
    "&.MuiTabPanel-root": {
      padding: "48px 0px ",
    },
  },
  tabPanelRootMobileNoPadding: {
    "&.MuiTabPanel-root": {
      [theme.breakpoints.down("xs")]: {
        padding: "0px ",
      },
    },
  },
  stepTitle: {
    position: "relative",
    left: "-24px",
    borderLeft: "4px solid",
    borderLeftColor: primaryColor,
    paddingLeft: "24px",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "29px",
    marginBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      borderLeft: "none",
      left: "0px",
    },
  },
  navigationButtonsContainer: {
    marginTop: "24px",
    paddingTop: "48px",
    borderTop: "1px solid",
    borderTopColor: borderColor,
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      marginTop: "12px",
      borderTop: "none",
    },
  },
  hidden: {
    visibility: "hidden",
  },
  option: {
    padding: "20px 25px",
    textTransform: "none",
    width: "fit-content",
    border: `1px solid ${borderColor}`,
    borderRadius: "2px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: primaryColor10,
      borderColor,
    },
    "& img": {
      marginLeft: "4px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "12px 16px",
    },
  },
  optionGroupTitle: {
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "22px",
  },
  mobileFirstStep: {
    paddingTop: "180px",
    paddingBottom: "180px",
    display: "flex",
    flexDirection: "column",
    gap: "64px",
    width: "100%",
  },
  mobileFirstStepTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    textAlign: "center",
  },
  mobileFirstStepButton: {
    padding: "20px 25px",
    width: "fit-content",
    marginLeft: "16px",
  },
}));

export const useOptionStyles = makeStyles((theme) => ({
  option: {
    padding: "20px 25px",
    textTransform: "none",
    width: "fit-content",
    border: `1px solid ${borderColor}`,
    borderRadius: "2px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: primaryColor10,
      borderColor,
    },
    "& img": {
      marginLeft: "4px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "12px 16px",
    },
  },
  selected: {
    borderColor: primaryColor,
    backgroundColor: primaryColor10,
    "&:hover": {
      cursor: "pointer",
      borderColor: primaryColor,
      backgroundColor: primaryColor10,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
    "&:hover": {
      cursor: "not-allowed",
      backgroundColor: "transparent",
    },
  },
}));
