import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import "./index.scss";
import { Link, navigate } from "gatsby";
import cx from "classnames";

import { useStyles } from "../../../utils/loan-application-layout.styles";
import { useCommonStyles } from "../../../utils/loan-application.styles";
import { useCompanyInfo } from "../../../hooks";
import { DEFAULT_BROKER_DETAILS } from "../../../commons/enum";
import { GunnLogo, GunnLogoShorten, GunnLogoWhite } from "../../../images";

function LoanApplicationLayout({ children, location }) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { data: companyInfo = {} } = useCompanyInfo();
  const { theme = {}, adminDetails = {} } = companyInfo;
  const { imageUrl = "", user = {} } = adminDetails;
  const { firstName = "", lastName = "" } = user;
  const {
    iconDarkUrl = "",
    iconUrl = "",
    logoDarkUrl = "",
    logoUrl = "",
  } = theme;
  const { pathname } = location;
  const shouldShowBrokerInfo = !pathname.includes("/privacy"); // do not show broker info in privacy page

  const phoneNumber =
    companyInfo?.businessPhoneNumber ||
    user?.phoneNumber ||
    DEFAULT_BROKER_DETAILS.phoneNumber;
  const email =
    companyInfo?.customerSupportEmail ||
    user?.email ||
    DEFAULT_BROKER_DETAILS.supportEmail;

  return (
    <div className="loan-app-page-container">
      <AppBar
        color="transparent"
        position="static"
        classes={{ root: classes.appBar }}
      >
        <Grid container xs={12}>
          <Grid item xs={1} />
          <Grid container xs={10} classes={{ root: classes.appBarContent }}>
            <Box
              classes={{ root: classes.logoButton }}
              onClick={() => navigate("/loan-application")}
            >
              <img
                src={logoDarkUrl || GunnLogo}
                alt="app-logo"
                className="loan-app-logo-desktop"
              />
              <img
                src={iconDarkUrl || logoDarkUrl || GunnLogoShorten}
                alt="app-logo"
                className="loan-app-logo-mobile"
              />
            </Box>
            <Box
              classes={{
                root: cx(
                  classes.quickNavigationButtonsContainer,
                  classes.mobileNoPadding
                ),
              }}
            >
              <Button
                component={Link}
                to="/login"
                variant="contained"
                color="primary"
                classes={{
                  root: cx(
                    classes.button,
                    commonClasses.fontSize16,
                    commonClasses.textWhite
                  ),
                  containedPrimary: classes.clientLoginButton,
                }}
              >
                Client Login
              </Button>
            </Box>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </AppBar>
      <Grid container classes={{ root: classes.content }}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {shouldShowBrokerInfo && (
            <Container
              maxWidth={false}
              classes={{
                root: cx(
                  classes.brokerInformationSection,
                  commonClasses.desktopDisplay
                ),
              }}
            >
              <Grid container>
                <Grid
                  container
                  xs={5}
                  md={6}
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Typography
                      classes={{
                        root: classes.brokerInformationContainerTitle,
                      }}
                    >
                      Quick Online Application, <br /> That Get’s You Answers
                      Fast!
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography classes={{ root: commonClasses.fontSize18 }}>
                      <Typography
                        variant="inherit"
                        classes={{
                          root: cx(
                            classes.underlineHighlighted,
                            commonClasses.fontSize18
                          ),
                        }}
                      >
                        (This does not
                      </Typography>{" "}
                      effect your credit score)
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={7} md={6}>
                  <Box classes={{ root: classes.brokerInformationContainer }}>
                    <Box classes={{ root: classes.brokerInformationWrapper }}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        gridRowGap="8px"
                      >
                        <Typography
                          classes={{ root: commonClasses.fontSize18 }}
                        >
                          <strong>
                            {firstName} {lastName}
                          </strong>
                        </Typography>
                        <Typography
                          classes={{ root: commonClasses.fontSize18 }}
                        >
                          Mortgage Broker
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        gridRowGap="8px"
                      >
                        <Typography
                          classes={{ root: commonClasses.fontSize18 }}
                        >
                          E: {email}
                        </Typography>
                        <Typography
                          classes={{ root: commonClasses.fontSize18 }}
                        >
                          M: {phoneNumber}
                        </Typography>
                      </Box>
                    </Box>
                    <Box width="fit-content">
                      <div className="loan-app-broker-avatar-wrapper">
                        <img
                          src={imageUrl}
                          alt="broker-avater"
                          className="loan-app-broker-avatar"
                        />
                        <div className="loan-app-broker-avatar-shadow-box__inner" />
                        <div className="loan-app-broker-avatar-shadow-box__outer" />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          )}
          <Grid container className="loan-app-content-container">
            {children}
          </Grid>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid container>
        <Grid container classes={{ root: classes.footer }} xs={12}>
          <Grid item xs={2} sm={1} />
          <Grid container xs={8} sm={10}>
            <Grid container xs={12} sm={8}>
              <Box classes={{ root: classes.footerNavigation }}>
                <img
                  src={logoUrl || GunnLogoWhite}
                  alt="app-logo-white"
                  className="loan-app-logo-white-desktop"
                />
                <img
                  src={iconUrl || logoUrl || GunnLogoWhite}
                  alt="app-logo-white"
                  className="loan-app-logo-white-mobile"
                />
                <Box
                  classes={{
                    root: cx(classes.footerLinkContainer),
                  }}
                >
                  <Button
                    component={Link}
                    to="/loan-application"
                    classes={{ root: classes.footerButton }}
                  >
                    Home
                  </Button>
                  <Button
                    component={Link}
                    to="/signup"
                    classes={{ root: classes.footerButton }}
                  >
                    Signup
                  </Button>
                  <Button
                    component={Link}
                    to="/login"
                    classes={{ root: classes.footerButton }}
                  >
                    Login
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              xs={12}
              sm={4}
              classes={{ root: classes.contactInformationWrapper }}
            >
              <Box classes={{ root: classes.contactInformationContainer }}>
                <Box
                  classes={{
                    root: cx(classes.shownOnMobile, classes.marginBottom16),
                  }}
                >
                  <Typography
                    classes={{
                      root: cx(
                        commonClasses.fontSize18,
                        commonClasses.textWhite
                      ),
                    }}
                  >
                    Just a phone call away <br /> if you need help.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    classes={{
                      root: cx(
                        classes.title,
                        commonClasses.fontSize24,
                        commonClasses.textWhite,
                        classes.hiddenOnMobile
                      ),
                    }}
                  >
                    Get In Touch
                  </Typography>
                  <Typography
                    classes={{
                      root: cx(
                        classes.workingHours,
                        commonClasses.fontSize18,
                        commonClasses.textWhite
                      ),
                    }}
                  >
                    Weekdays 8 AM - 8 PM AEST
                  </Typography>
                  <Typography
                    classes={{
                      root: cx(
                        classes.workingHours,
                        commonClasses.fontSize18,
                        commonClasses.textWhite
                      ),
                    }}
                  >
                    Saturday 10 AM - 4 PM
                  </Typography>
                  <Typography
                    classes={{
                      root: cx(
                        classes.workingHours,
                        commonClasses.fontSize18,
                        commonClasses.textWhite
                      ),
                    }}
                  >
                    Sunday We Enjoy Australia
                  </Typography>
                </Box>
                <Box classes={{ root: classes.contactInformation }}>
                  <Typography
                    classes={{
                      root: cx(
                        commonClasses.fontSize18,
                        commonClasses.textWhite
                      ),
                    }}
                  >
                    <b>Call:</b> {phoneNumber}
                  </Typography>
                  <Typography
                    classes={{
                      root: cx(
                        commonClasses.fontSize18,
                        commonClasses.textWhite
                      ),
                    }}
                  >
                    <b>Email:</b> {email}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={1} />
        </Grid>
        <Grid
          container
          classes={{ root: classes.siteBuildInformation }}
          xs={12}
        >
          <Grid item xs={2} sm={1} />
          <Grid
            container
            xs={8}
            sm={7}
            classes={{ root: classes.companyInformation }}
          >
            <Typography
              classes={{
                root: cx(
                  classes.licenseInformation,
                  commonClasses.fontSize12,
                  commonClasses.fontWeightLight,
                  commonClasses.textWhite,
                  commonClasses.textUppercase,
                  classes.hiddenOnMobile
                ),
              }}
            >
              ALL APPLICANTS ARE SUBJECT TO ELIGIBILITY AND ASSESSMENT{" "}
              {`${companyInfo?.name || DEFAULT_BROKER_DETAILS.companyName}`} PTY
              LTD | ABN{" "}
              {`${
                companyInfo?.companyNumber ||
                DEFAULT_BROKER_DETAILS.companyNumber
              }`}{" "}
              | AUSTRALIAN CREDIT LICENCE{" "}
              {`${
                companyInfo?.creditRepNumber ||
                DEFAULT_BROKER_DETAILS.creditRepNumber
              }`}
            </Typography>
            <Typography
              classes={{
                root: cx(
                  classes.licenseInformation,
                  commonClasses.fontSize12,
                  commonClasses.fontWeightLight,
                  commonClasses.textWhite,
                  commonClasses.textUppercase,
                  classes.shownOnMobile
                ),
              }}
            >
              {`${companyInfo?.name || DEFAULT_BROKER_DETAILS.companyName}`} PTY
              LTD | ABN{" "}
              {`${
                companyInfo?.companyNumber ||
                DEFAULT_BROKER_DETAILS.companyNumber
              }`}
            </Typography>
          </Grid>
          <Grid
            container
            xs={false}
            sm={3}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box classes={{ root: classes.siteBuiltBy }}>
              <Typography
                component={Link}
                to="/loan-application/privacy"
                classes={{
                  root: cx(
                    commonClasses.fontSize16,
                    commonClasses.fontWeightLight,
                    commonClasses.textWhite
                  ),
                }}
              >
                Privacy & Policy
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={1} />
        </Grid>
      </Grid>
    </div>
  );
}

export default LoanApplicationLayout;
