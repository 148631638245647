/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { useMediaQuery } from "@material-ui/core";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useIntercom } from "react-use-intercom";
import { getCompanyInfo } from "../api/company";
import { NOT_DASHBOARD_ROUTES } from "../commons/enum";
import calculateSummaryData from "../components/Layout/calculate-summary-data";
import CheckUserLoggedInContext from "../context/check-user-logged-in-context";
import DataLoadingContext from "../context/data-loading-context";
import { getcompanyDomain } from "../utils";
import {
  useLastXDaysFinanceHistory,
  useLinkedBankAccountData,
} from "./account.hooks";
import { usePropertyList } from "./property.hooks";
import { useScenarioList } from "./scenario.hooks";
import { useIsLoggedIn, useMyInfo } from "./user.hooks";
import { hexToRgbaHex } from "../utils/styles-util";

const changeFavicon = (iconUrl) => {
  // Remove all existing favicons
  document
    .querySelectorAll("link[rel='icon']")
    .forEach((icon) => icon.remove());

  // Add new favicon
  const link = document.createElement("link");
  link.rel = "icon";
  link.href = iconUrl;
  document.head.appendChild(link);
};

export const useIsInDashboardRoutes = (pathname) => {
  return useMemo(() => {
    for (const route of NOT_DASHBOARD_ROUTES) {
      if (pathname.indexOf(route) >= 0) {
        return false;
      }
    }
    return true;
  }, [pathname]);
};

export const useShouldRedirectWhenAuthenticated = () => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();
  const { shouldPauseAuthenticatedRedirect } = useContext(DataLoadingContext);
  const { checkUserLoggedIn } = useContext(CheckUserLoggedInContext);
  const isInDashboardRoutes = useIsInDashboardRoutes(location.pathname);
  return useMemo(() => {
    if (
      isLoggedIn &&
      !isInDashboardRoutes &&
      !shouldPauseAuthenticatedRedirect
    ) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoggedIn,
    checkUserLoggedIn,
    isInDashboardRoutes,
    shouldPauseAuthenticatedRedirect,
  ]);
};

export const useFinanceSummary = () => {
  const { data: last30DaysFinanceHistory } = useLastXDaysFinanceHistory({
    numberOfDays: 30,
  });
  const linkedBankAccountData = useLinkedBankAccountData();
  const { data: propertyList } = usePropertyList();
  const { data: scenarioList } = useScenarioList();

  return useMemo(() => {
    return calculateSummaryData(
      last30DaysFinanceHistory,
      linkedBankAccountData,
      propertyList,
      scenarioList
    );
  }, [
    last30DaysFinanceHistory,
    linkedBankAccountData,
    propertyList,
    scenarioList,
  ]);
};

export const useStartLiveChat = () => {
  const { data: userInfo } = useMyInfo();
  const { boot, show } = useIntercom();

  const {
    firstName = "Anonymous",
    lastName = "",
    id = "",
    email = "",
  } = userInfo;
  const fullName = `${firstName} ${lastName}`;
  const user = {
    user_id: id,
    email,
    name: fullName,
  };

  const startLiveChat = () => {
    boot(user);
    show();
  };

  return [startLiveChat];
};

export const useIsDesktopSmall = () => {
  return useMediaQuery("(max-width:1600px)");
};

export const useIsTablet = () => {
  return useMediaQuery("(max-width: 1024px)");
};

export const useIsMobile = () => {
  return useMediaQuery("(max-width: 767px)");
};

export const useCompanyInfo = () => {
  const companyDomain = getcompanyDomain();
  return useQuery(
    ["companyInfo", companyDomain],
    () =>
      getCompanyInfo({
        params: { domain: companyDomain },
      }),
    {
      onSuccess: (data) => {
        const pageLayout = document.documentElement;
        pageLayout.style.setProperty(
          "--secondaryColor",
          `${data?.theme?.secondaryColor || "#162029"}`
        );
        pageLayout.style.setProperty(
          "--primaryColor",
          `${data?.theme?.primaryColor || "#04f73d"}`
        );
        pageLayout.style.setProperty(
          "--primaryColor10",
          `${hexToRgbaHex(data?.theme?.primaryColor || "#04f73d", 0.1)}`
        );
        if (data?.theme?.iconUrl) {
          changeFavicon(data.theme.iconUrl);
        }
      },
      onError: (error) => {
        if (error.status === 404) {
          navigate("/page-error");
        }
      },
      retry: 1,
    }
  );
};

export const useCompanyInfoById = (companyId) => {
  return useQuery(
    ["companyInfo", companyId],
    () =>
      getCompanyInfo({
        params: { companyId },
      }),
    {
      enabled: !!companyId,
      onError: (error) => {
        if (error.status === 404) {
          navigate("/page-error");
        }
      },
      retry: 1,
    }
  );
};

export const useCompanyTheme = () => {
  const { data: companyInfo } = useCompanyInfo();
  return companyInfo?.theme;
};

export const useCompanyLogo = () => {
  const companyTheme = useCompanyTheme();
  const companyLightLogo = companyTheme?.logoURL || companyTheme?.iconUrl;
  const companyDarkLogo =
    companyTheme?.logoDarkUrl || companyTheme?.iconDarkUrl;
  return {
    lightLogo: companyLightLogo,
    darkLogo: companyDarkLogo,
  };
};

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useDebouncedFunc = (func, delay = 300) => {
  const [id, setId] = useState(null);

  return (...args) => {
    if (id) {
      clearTimeout(id);
    }
    setId(
      setTimeout(() => {
        func(...args);
        setId(null);
      }, delay)
    );
  };
};
