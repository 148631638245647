import { useContext, useEffect, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import {
  createScenario,
  getBuyingPowerHistoryByScenario,
  getMyQuickLoan,
  getProductListByScenarioId,
  getScenario,
  getServiceProviders,
  updateBorrowCapacity,
  updateUserEmployment,
} from "../api/scenario";
import { FinanceContext } from "../context/finance-context";
import { calculateLvr } from "../utils/scenario-utils";
import { useQueryInvalidationWithNotification } from "./util.hooks";
import { useIsLoggedIn, useMyInfo } from "./user.hooks.ts";

export const useIsScenarioOwner = () => {
  const { data: userInfo = {} } = useMyInfo();
  const { selectedScenarioDetail } = useContext(FinanceContext);
  return selectedScenarioDetail?.mainUserId === userInfo?.id;
};

export const useScenarioList = (queryOptions) => {
  return useQuery("scenarioList", getScenario, { ...queryOptions, retry: 2 });
};

export const useSortedScenarioList = (enabled = false) => {
  const { data: scenarioList } = useScenarioList({ enabled });
  return useMemo(
    () =>
      scenarioList
        ?.slice()
        ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
    [scenarioList]
  );
};

export const useServiceProviders = (queryOptions) => {
  return useQuery("serviceProviders", getServiceProviders, {
    ...queryOptions,
    retry: 2,
  });
};

export const useCreateScenarioMutation = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation(createScenario, {
    onSuccess: () => {
      invalidateQueries("scenarioList");
    },
  });
};

export const useUpdateBorrowCapacity = () => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useMutation(updateBorrowCapacity, {
    onSuccess: () => {
      invalidateQueries("scenarioList");
      invalidateQueries("scenarioProductList");
      invalidateQueries("maxBuyingPowerHistoryChartData");
      invalidateQueries("maxLoanHistoryChartData");
    },
  });
};

export const useUpdateUserEmployment = () => {
  return useMutation(updateUserEmployment);
};

export const useScenarioProductListByScenarioId = (scenarioId) => {
  const invalidateQueries = useQueryInvalidationWithNotification();
  return useQuery(
    ["scenarioProductList", scenarioId],
    () => getProductListByScenarioId(scenarioId),

    {
      onSuccess: () => {
        invalidateQueries("scenarioList");
      },
      enabled: !!scenarioId,
      retry: 3,
    }
  );
};

export const useSelectedScenarioIdCorrection = (
  scenarioList,
  selectedScenarioId,
  setSelectedScenarioId
) => {
  useEffect(() => {
    const foundSelectedScenario = scenarioList?.find(
      (scenario) => scenario.id === selectedScenarioId
    );
    if (!foundSelectedScenario) {
      if (scenarioList?.length > 0) {
        setSelectedScenarioId(scenarioList[0].id);
      } else {
        setSelectedScenarioId("");
      }
    }
  }, [scenarioList, selectedScenarioId, setSelectedScenarioId]);
};

export const useLvr = (estimateProperty, cashTowardProperty) => {
  return useMemo(() => {
    return calculateLvr(estimateProperty, cashTowardProperty);
  }, [estimateProperty, cashTowardProperty]);
};

export const useBuyingPowerHistoryByScenario = (scenarioId) => {
  return useQuery(
    ["buyingPowerHistoryByScenario", scenarioId],
    () => getBuyingPowerHistoryByScenario(scenarioId),
    {
      enabled: !!scenarioId,
    }
  );
};

export const useGetMyQuickLoan = (options = {}) => {
  const isLoggedIn = useIsLoggedIn();
  return useQuery("myQuickLoan", getMyQuickLoan, {
    enabled: !!isLoggedIn,
    ...options,
  });
};
