import axios from "axios";
import queryString from "query-string";
import { NOT_DASHBOARD_ROUTES } from "../commons/enum";
import { getCurrentPath } from "../utils";
import { isBrowser } from "../utils/miscellaneous-util";

export const getHostName = () => {
  if (process.env.GATSBY_APP_HOST) {
    return process.env.GATSBY_APP_HOST;
  }
  if (isBrowser()) {
    const { hostname } = window.location;
    const splittedHostname = hostname.split(".");
    if (splittedHostname.includes("staging")) {
      const index = splittedHostname.lastIndexOf("staging");
      return splittedHostname.slice(index).join(".");
    }
    if (splittedHostname.includes("app")) {
      const index = splittedHostname.lastIndexOf("app");
      return splittedHostname.slice(index + 1).join(".");
    }
    if (splittedHostname.includes("backoffice")) {
      const index = splittedHostname.lastIndexOf("backoffice");
      return splittedHostname.slice(index + 1).join(".");
    }
    return window.location.hostname;
  }
  return "";
};

const isDevelopmentEnv =
  process.env.ACTIVE_ENV === "development" ||
  process.env.NODE_ENV === "development";

export const checkIsPrivateRoute = () => {
  const pathname = getCurrentPath();
  for (const route of NOT_DASHBOARD_ROUTES) {
    if (pathname.indexOf(route) >= 0) {
      return false;
    }
  }
  return true;
};

export const getAppAPIUrl = () => {
  const URL = `${isDevelopmentEnv ? "http" : "https"}://api.${getHostName()}${
    isDevelopmentEnv ? ":3000" : ""
  }/api`;
  return URL;
};
const axiosClient = axios.create({
  baseURL: getAppAPIUrl(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  const { method } = config;
  if (method !== "get") {
    axiosClient.defaults.headers.common["csrf-token"] = token;
  }
  return config;
});

axiosClient.interceptors.response.use((response) => {
  return response?.data;
});

export default axiosClient;
