import React, { useMemo } from "react";
import { useBrokerInfo, useDefaultBroker } from "../../hooks/broker.hooks";
import { useInviterName } from "../../hooks/sharing.hooks";
import "../../scss/sharing-referral.scss";
import {
  extractBrokerReferralFromUrl,
  extractUserReferralFromUrl,
  getCurrentLocation,
} from "../../utils/get-params-value";
import { titleCase } from "../../utils/text-format";
import { LoadingBackdrop } from "../loading-backdrop";
import NavigationBar from "./components/navigation-bar";
import NotFoundBroker from "./components/not-found-broker";
import SharingContentWithSignup from "./components/sharing-content-with-signup";

const SharingLayout = ({ children }) => {
  const location = getCurrentLocation();
  const [referralPath, referralCode] = extractUserReferralFromUrl(
    location.pathname
  );

  const {
    data: { referralName = "", staffCode: staffCodeFromUser = "" } = {},
    isError,
  } = useInviterName(referralPath, referralCode);

  const [isBrokerPage, staffCodeFromUrl] = extractBrokerReferralFromUrl(
    location.pathname
  );
  const defaultBrokerInfo = useDefaultBroker();
  const staffCode = staffCodeFromUrl || staffCodeFromUser;

  const {
    data: retrievedBrokerInfo = defaultBrokerInfo,
    isError: isGettingBrokerInfoError,
    status,
  } = useBrokerInfo(staffCode);

  const signupUrl = useMemo(() => {
    if (isBrokerPage && staffCode && !isGettingBrokerInfoError) {
      return `/signup?staffCode=${staffCode}`;
    }
    if (referralPath && referralCode && !isError) {
      return `/signup/${referralPath}/${referralCode}`;
    }
    return "/signup";
  }, [
    isGettingBrokerInfoError,
    staffCode,
    isBrokerPage,
    referralCode,
    referralPath,
    isError,
  ]);

  const renderPage = () => {
    if (isBrokerPage && status === "error") return <NotFoundBroker />;
    return (
      <div>
        <NavigationBar signupUrl={signupUrl} />
        <SharingContentWithSignup
          name={titleCase(referralName)}
          isBrokerPage={isBrokerPage}
          brokerName={titleCase(retrievedBrokerInfo?.name || "")}
          brokerInfo={retrievedBrokerInfo}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              staffCode,
            })
          )}
        </SharingContentWithSignup>
      </div>
    );
  };

  return <>{status === "success" ? renderPage() : <LoadingBackdrop />}</>;
};

SharingLayout.propTypes = {};

export default SharingLayout;
